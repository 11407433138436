import { Select } from 'Molecules';
import { AggregatedMetricsTableData } from './AggregatedMetrics.hooks';
import { Button } from 'Atoms';
import { CommentIcon } from 'Tokens/Icons/Function';
import { useMemo } from 'react';
import { Typography } from 'Tokens';
import { TimePeriodsEnums } from '../../Requirement';
import { useNotifications } from 'Features';
import { useGetDatapointValues } from '../MetricsTable/MetricsTableComponents/InputFields/QuantitativeInputs/QuantitativeInputs.hooks';

export enum GroupOrderOptions {
  byType = 'Group by type',
  byEsrsOrder = 'ESRS order',
}

export const GroupOrderSelector = ({
  groupOrOrder,
  setGroupOrOrder,
}: {
  groupOrOrder: GroupOrderOptions;
  setGroupOrOrder: (val: GroupOrderOptions) => void;
}) => {
  return (
    <Select
      isSearchable={false}
      ghostVariant
      size="sm"
      width="fit-content"
      menuWidth="150px"
      defaultValue={{ label: groupOrOrder, value: groupOrOrder }}
      onChange={(val) => setGroupOrOrder(val?.value ?? GroupOrderOptions.byType)}
      options={[
        {
          label: GroupOrderOptions.byType,
          value: GroupOrderOptions.byType,
        },
        {
          label: GroupOrderOptions.byEsrsOrder,
          value: GroupOrderOptions.byEsrsOrder,
        },
      ]}
    />
  );
};

export const QuarterSelector = ({
  defaultQuarter,
  setSelectedQuarter,
}: {
  defaultQuarter: TimePeriodsEnums;
  setSelectedQuarter: (val: TimePeriodsEnums) => void;
}) => {
  return (
    <Select
      isSearchable={false}
      ghostVariant
      size="sm"
      width="fit-content"
      menuWidth="110px"
      onChange={(val) => setSelectedQuarter(val?.value ?? TimePeriodsEnums.year)}
      defaultValue={{
        label: defaultQuarter === TimePeriodsEnums.year ? 'Full year' : defaultQuarter,
        value: defaultQuarter,
      }}
      options={[
        {
          label: 'Full year' as TimePeriodsEnums,
          value: TimePeriodsEnums.year,
        },
        {
          label: TimePeriodsEnums.q1,
          value: TimePeriodsEnums.q1,
        },
        {
          label: TimePeriodsEnums.q2,
          value: TimePeriodsEnums.q2,
        },
        {
          label: TimePeriodsEnums.q3,
          value: TimePeriodsEnums.q3,
        },
        {
          label: TimePeriodsEnums.q4,
          value: TimePeriodsEnums.q4,
        },
      ]}
    />
  );
};

export const CommentsCounter = ({
  row,
  selectedQuarter,
  companyReportingUnit,
  onClick,
}: {
  row: AggregatedMetricsTableData;
  selectedQuarter: string;
  companyReportingUnit?: string;
  onClick: () => void;
}) => {
  const { answer } = useGetDatapointValues(row, companyReportingUnit);
  const { notifications } = useNotifications();

  const dataPoint = useMemo(
    () => answer?.datapoints.find((dp) => dp.timeframe === selectedQuarter),
    [answer, selectedQuarter]
  );

  const commentsCount = useMemo(
    () => dataPoint?.thread?.comments_aggregate.aggregate?.count ?? 0,
    [dataPoint]
  );

  const hasUnread = useMemo(
    () =>
      notifications.some(
        (notf) => notf.comment.thread.esrsDatapoint?.id === dataPoint?.id && !notf.isRead
      ),
    [notifications, dataPoint]
  );

  return (
    <Button
      size="sm"
      variant="ghost"
      leftIcon={<CommentIcon color={hasUnread ? 'text.action' : 'text.muted'} />}
      onClick={onClick}
    >
      <Typography
        variant="bodyStrong"
        color={hasUnread ? 'text.action' : 'text.muted'}
        opacity={commentsCount === 0 ? 0.2 : 1}
      >
        {commentsCount}
      </Typography>
    </Button>
  );
};
