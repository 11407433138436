import { DataCollectionLevel } from 'containers/Esrs/pieces/DataCollection';
import { FrequencyEnums } from 'containers/Esrs/pieces/DisclosureRequirements/Requirement';
import { useMemo } from 'react';
import {
  AggregatedMetricsTableData,
  AggregatedQualitativeAnswers,
} from '../../../AggregatedMetrics';

export const useCheckProgress = (
  row: AggregatedMetricsTableData,
  companyStandardId: string,
  answersData?: AggregatedQualitativeAnswers,
  companyReportingUnit?: string
) => {
  const isAggregated = useMemo(() => {
    return !!row?.subRows?.length;
  }, [row]);
  const materialMetric = useMemo(() => row.metric.materialMetrics?.[0], [row]);
  const isSubsidiaries = useMemo(
    () => materialMetric?.dataCollection === DataCollectionLevel.subsidiaries,
    [materialMetric]
  );
  const isYearly = useMemo(
    () => materialMetric?.frequency === FrequencyEnums.yearly,
    [materialMetric]
  );

  const numberOfSubs = useMemo(
    () =>
      answersData?.find((data) => data.metricRef === row.metric?.reference)?.subsidiaries?.length ??
      0,
    [answersData]
  );

  const answeredSubs = useMemo(
    () =>
      answersData
        ?.find((data) => data.metricRef === row.metric?.reference)
        ?.subsidiaries?.filter((sub) => !!sub.answer).length ?? 0,
    [answersData]
  );

  const dataPointsPerQuarter = useMemo(
    () =>
      Object.entries(row.result ?? {})
        .filter(([key]) => key !== 'Year')
        .map(([key, value]) => ({
          field: key.toUpperCase(),
          value: value,
        })),
    [row]
  );

  if (isAggregated) {
    let total = 0;
    if (!!row.subRows) {
      // This should be fixed asap!!
      row.subRows.forEach((subRow) => {
        total += useCheckProgress(subRow, companyStandardId, answersData, companyReportingUnit);
      });
      return Math.floor(total / row.subRows.length);
    }
  }

  if (isSubsidiaries) {
    if (isYearly) {
      if (row.result?.Year !== 0) return (answeredSubs / numberOfSubs) * 100;
      else return 0;
    }
  }
  const isAssessedYearly = row.result?.Year ? true : false;
  const assessedQuarters = isAggregated
    ? []
    : dataPointsPerQuarter.filter((item) => item.value !== 0);
  return isYearly ? (isAssessedYearly ? 100 : 0) : (assessedQuarters.length * 100) / 4;
};
