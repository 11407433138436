import { HStack, VStack } from '@chakra-ui/react';
import { MetricTypes, MetricTypeIcon } from 'Molecules/MetricTypeIcon';
import { TableData } from 'Molecules/NestedTable';
import { DataCollectionLevel } from 'containers/Esrs/pieces/DataCollection';
import { FrequencyEnums } from 'containers/Esrs/pieces/DisclosureRequirements/Requirement';
import React, { useMemo, useRef, MutableRefObject } from 'react';
import { getRowName } from './MetricRowTitle.hooks';
import { MetricsTableData, getMetricWithChildren } from '../../../MetricAnswers.hooks';
import { Row } from '@tanstack/react-table';
import { QuestionType_Enum_ } from 'models';
import { MetricNameWithTag } from 'containers/Esrs/pieces/DataCollection/DataCollectionUtils';

export const MetricRowTitle = ({
  row,
  rowData,
  companyStandardId,
  onClick,
  isAggregated,
  isNarrative,
}: {
  row: Row<TableData<MetricsTableData>>;
  rowData?: MetricsTableData;
  companyStandardId: string;
  onClick?: () => void;
  isAggregated?: boolean;
  isNarrative?: boolean;
}) => {
  const isRowNarrative = useMemo(
    () => row.original.metric.metricType !== QuestionType_Enum_.Decimal_,
    [row]
  );
  const materialMetric = useMemo(
    () =>
      row.original.metric.materialMetrics?.find(
        (mm) => mm.materialStandardId === companyStandardId
      ),
    [row, companyStandardId]
  );

  const [isCalculated, isChildMetric] = useMemo(() => {
    const isChild =
      row.getParentRow()?.original.tagName &&
      !row.getParentRow()?.original.tags?.length &&
      row.original.parentMetric;

    const calculated = row.getCanExpand() && !!row.original.metric.calculation;

    return [calculated, isChild];
  }, [row]);

  const rowType = useMemo(() => {
    if (row.original.locked) return MetricTypes.locked;
    if (isRowNarrative) return MetricTypes.text;
    if (isCalculated) return MetricTypes.calculated;
    if (isAggregated) return MetricTypes.aggregated;
    if (
      row.original.metric.materialMetrics?.[0]?.dataCollection === DataCollectionLevel.subsidiaries
    )
      return MetricTypes.aggregated;
    else if (!!row.subRows.length) return MetricTypes.aggregated;
    return MetricTypes.number;
  }, [row, isCalculated, isRowNarrative]);

  const subMetrics = useMemo(() => {
    if (isAggregated) {
      const metrics = getMetricWithChildren(row.original).slice(1);
      return metrics?.map((metricRow) => ({
        name: metricRow.metric?.shortTitle ?? metricRow.metric?.title ?? '',
        reference: metricRow.metric?.reference ?? '',
      }));
    }
    return row.subRows.map((subRow) => ({
      name: getRowName(subRow.original, true) ?? '',
      reference: subRow.original.metric.reference,
    }));
  }, [row]);

  const rowTitle = useMemo(() => {
    if (row.original.isAdditionalInfo) return 'Additional information';
    if (row.original.locked)
      return `${row.original.lockedCount} more data points if selected “Yes” above`;
    if (isChildMetric) return row.original.metric.shortTitle ?? row.original.metric.title;
    if (row.original.tagName) return row.original.tagName ?? '';
    return row.original.metric.shortTitle ?? row.original.metric.title;
  }, [row]);

  const parentRowTitle = useMemo(() => {
    if (row.original.tagName) {
      return (
        row.getParentRow()?.original.tagName ??
        row.getParentRow()?.original.metric.shortTitle ??
        row.getParentRow()?.original.metric.title
      );
    }
  }, [row]);

  const showTags = useMemo(
    () => (row.original.tagType || !row.original.isChild) && !row.original.tagName,
    [row]
  );

  const materialMetricTags = useMemo(
    () => (showTags ? materialMetric?.materialMetricTags?.map((tag) => tag.tagType) ?? [] : []),
    [materialMetric, showTags]
  );

  const metricNameRef = useRef<HTMLDivElement>(null);

  const calculateRowPadding = () => {
    if (isNarrative) return row.depth * 24;
    return row.depth * 24 + (row.getCanExpand() || !row.depth ? 0 : 44);
  };

  return (
    <HStack
      className="metricTitle"
      textDecoration="underline"
      textDecorationColor={row.original === rowData ? 'text.hint' : 'transparent'}
      textUnderlineOffset={row.original === rowData ? '2px' : '4px'}
      transition="0.15s"
      padding={isNarrative ? '14px' : undefined}
      pl={`${calculateRowPadding()}px`}
      spacing="8px"
      cursor={row.original.isAdditionalInfo ? '' : 'pointer'}
      width="100%"
      alignItems="start"
    >
      <MetricTypeIcon
        type={rowType}
        isBusinessUnits={materialMetric?.dataCollection === DataCollectionLevel.reportingUnits}
        isSubsidiaries={materialMetric?.dataCollection === DataCollectionLevel.subsidiaries}
        isQuarterly={materialMetric?.frequency === FrequencyEnums.quarterly}
        calculation={row.original.metric.calculation ?? undefined}
        subMetrics={subMetrics}
      />
      <VStack
        ref={metricNameRef}
        width="100%"
        onClick={onClick}
        height="fit-content"
        justifyContent="center"
        alignItems="start"
      >
        <MetricNameWithTag
          name={rowTitle}
          parentRowName={parentRowTitle}
          tags={materialMetricTags}
          textColor={row.original.locked ? 'text.disabled' : undefined}
          rowRef={metricNameRef as MutableRefObject<HTMLDivElement>}
          isNarrative={isNarrative}
        />
      </VStack>
    </HStack>
  );
};
