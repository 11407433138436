import {
  QUARTERS_FIELDS,
  TimePeriodsEnums,
} from 'containers/Esrs/pieces/DisclosureRequirements/Requirement';
import { useMemo } from 'react';
import { QuestionType } from 'utils/scores/questions';
import {
  MetricsTableData,
  areArraysOfObjectsEqual,
  useEsrsAnswer,
} from '../../../../MetricAnswers.hooks';

export const useGetDatapointValues = (row: MetricsTableData, companyReportingUnit?: string) => {
  const { answer, datapoints, onDatapointChange, loading } = useEsrsAnswer(
    row.metric?.reference,
    companyReportingUnit
  );

  const dataPointsPerQuarter = useMemo(() => {
    return QUARTERS_FIELDS.map((field) => {
      const correspondingDatapoint = datapoints?.find((datapoint) =>
        row?.tags?.length
          ? areArraysOfObjectsEqual(row?.tags, datapoint.datapointTags) &&
            datapoint.timeframe === field
          : datapoint.timeframe === field && !datapoint.datapointTags.length
      );
      return { field: field, value: correspondingDatapoint };
    });
  }, [datapoints]);

  const dataPointPerYear = useMemo(() => {
    if (row.metric?.metricType === QuestionType.LongText_) {
      return datapoints?.find((datapoint) => datapoint.timeframe === TimePeriodsEnums.year);
    }
    const correspondingDatapoint = datapoints?.find((datapoint) =>
      row?.tags?.length
        ? areArraysOfObjectsEqual(row?.tags, datapoint.datapointTags) &&
          datapoint.timeframe === TimePeriodsEnums.year
        : datapoint.timeframe === TimePeriodsEnums.year && !datapoint.datapointTags.length
    );
    return correspondingDatapoint;
  }, [datapoints]);

  const isAggregated = useMemo(() => {
    return !!row?.subRows?.length;
  }, [row]);

  const datapointText = useMemo(
    () => (row.metric?.metricType === QuestionType.LongText_ ? datapoints[0] : undefined),
    [datapoints]
  );

  return {
    answer,
    datapoints,
    onDatapointChange,
    loading,
    dataPointsPerQuarter,
    dataPointPerYear,
    datapointText,
    isAggregated,
  };
};
