import { Box, HStack, Skeleton, Spinner, VStack } from '@chakra-ui/react';
import { AutoResizeTextarea } from 'Atoms';
import { MenuSelector } from 'Molecules/Menu/MenuSelector';
import {
  DatapointSourceEnum,
  TimePeriodsEnums,
} from 'containers/Esrs/pieces/DisclosureRequirements/Requirement';
import { debounce } from 'lodash';
import React, { useMemo, useState, useEffect, useCallback, FC, useRef } from 'react';
import { MetricsTableData } from '../../../..';
import {
  AggregatedMetricsTableData,
  AggregatedQualitativeAnswers,
} from '../../../../AggregatedMetrics';
import { GeneratedAnswer } from '../../../../MetricAI';
import { QuestionType_Enum_ } from 'models';
import { useGetDatapointValues } from '../QuantitativeInputs/QuantitativeInputs.hooks';
import { BooleanSelector } from 'Molecules';
import { Typography } from 'Tokens';
import { booleanToText, textToBoolean } from 'utils/strings';
import { WarningIcon } from '@chakra-ui/icons';
import { useSearchParams } from 'react-router-dom';
import { AIInfoWarning, AISummaryAvailable, TextMetricProps } from './NarrativeInputUtils';

const LongTextMetricInput: FC<TextMetricProps> = React.memo(
  ({
    metric,
    companyReportingUnit,
    isGeneratingAnswers = false,
    rowData,
    setRowData,
    answersData,
    hasRef = true,
    generatedAnswer,
    isReadOnly = false,
    isAI = false,
    placeholder,
  }) => {
    const [searchParams] = useSearchParams();
    const urlDatapointId = useMemo(() => searchParams.get('datapointId'), [searchParams]);
    const openDrawer = new URLSearchParams(location.search).get('openDrawer');
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [hasStartedTyping, setHasStartedTyping] = useState<boolean>(false);
    const { onDatapointChange, dataPointPerYear, answer, loading } = useGetDatapointValues(
      {
        metric,
      },
      companyReportingUnit
    );

    const metricAnswerData = useMemo(() => {
      return answersData?.find((data) => data.metricRef === metric?.reference);
    }, [answersData, metric]);

    const isIrrelevantResponse = useMemo(() => {
      return dataPointPerYear?.source === DatapointSourceEnum.AI && dataPointPerYear.value === '-';
    }, [dataPointPerYear]);

    const allSubsidiariesAnswered = useMemo(() => {
      return metricAnswerData?.subsidiaries?.every(
        (subsidiary) =>
          !!subsidiary.answer?.datapoints.find((dp) => dp.timeframe === TimePeriodsEnums.year)
            ?.value
      );
    }, [metricAnswerData]);

    const allBusinessUnitsAnswered = useMemo(() => {
      return metricAnswerData?.reportingUnits?.every(
        (bu) => !!bu.answer?.datapoints.find((dp) => dp.timeframe === TimePeriodsEnums.year)?.value
      );
    }, [metricAnswerData]);

    const isAIWriting = useMemo(() => {
      return isGeneratingAnswers;
    }, [isGeneratingAnswers]);

    // Use a local state for the input value to ensure it's controlled.
    const [inputValue, setInputValue] = useState(dataPointPerYear?.value ?? null);

    // Condition to show that AI summary is available
    const showAvailableSummary = useMemo(() => {
      return (
        (allSubsidiariesAnswered || allBusinessUnitsAnswered) &&
        dataPointPerYear?.source !== DatapointSourceEnum.AI &&
        !inputValue
      );
    }, [allSubsidiariesAnswered, allBusinessUnitsAnswered, dataPointPerYear, inputValue]);

    // Update the local input value whenever the external value changes.
    useEffect(() => {
      if (!hasStartedTyping) setInputValue(dataPointPerYear?.value ?? null);
    }, [dataPointPerYear?.value]);

    // Restored to rerender narrative answers when population finishes
    useEffect(() => {
      if (
        dataPointPerYear?.value !== inputValue &&
        dataPointPerYear?.source === DatapointSourceEnum.AI
      ) {
        setHasStartedTyping(false);
      }
    }, [dataPointPerYear, inputValue]);

    useEffect(() => {
      if (dataPointPerYear?.id === urlDatapointId && openDrawer) {
        setRowData?.({ metric: metric });
      }
    }, [urlDatapointId, dataPointPerYear, openDrawer]);

    // Update subsidiaries source data
    useEffect(() => {
      const hasReportingUnitsOrSubsidiaries =
        !!metricAnswerData?.reportingUnits?.length || !!metricAnswerData?.subsidiaries?.length;

      if (rowData?.metric.reference === metric.reference)
        if (hasReportingUnitsOrSubsidiaries) {
          setRowData?.({ ...rowData, sourceData: metricAnswerData });
        }
    }, [metricAnswerData, metric]);

    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.style.minHeight = 'auto';
        const tdHeight = textareaRef.current.parentElement?.parentElement?.clientHeight;
        textareaRef.current.style.height = tdHeight ? `${tdHeight - 12}px` : 'unset';
        textareaRef.current.style.minHeight = tdHeight ? `${tdHeight - 12}px` : 'unset';
        if (isIrrelevantResponse || showAvailableSummary) {
          textareaRef.current.style.height = '44px';
          textareaRef.current.style.minHeight = '44px';
        }
      }
    }, [textareaRef.current, loading, isIrrelevantResponse, inputValue]);

    // Debounce function to delay state update and minimize re-renders.
    const debounceSave = useCallback(
      debounce((newValue) => {
        onDatapointChange({
          value: newValue,
          factValue: dataPointPerYear?.factValue,
          hasOptedOut: answer?.hasOptedOut ?? false,
          optOutReason: answer?.optOutReason ?? '',
          dp: dataPointPerYear ?? { timeframe: TimePeriodsEnums.year },
        });
      }, 1000),
      [onDatapointChange, answer, dataPointPerYear]
    );

    // Handle input changes with a debounced call.
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = event.target.value === '' ? null : event.target.value;
      setInputValue(newValue);
      debounceSave(newValue);
      setHasStartedTyping(true);
    };

    if (loading) {
      return <Skeleton height="20px" width="100%" />;
    }

    if (generatedAnswer) {
      return (
        <VStack my="10px" alignItems="start" w="100%">
          <Typography variant="body">{generatedAnswer?.answer}</Typography>
          {generatedAnswer.answer === '-' && (
            <HStack spacing="4px" alignItems="flex-start" justifyContent="start">
              <WarningIcon color="text.warning" boxSize="18px"></WarningIcon>
              <Typography variant="body" color="text.warning">
                AI couldn't generate answer
              </Typography>
            </HStack>
          )}
        </VStack>
      );
    }

    if (isAIWriting) {
      return (
        <HStack my="10px">
          <Spinner boxSize="16px" color="bg.brand.accent" />
          <Typography variant="body">AI is writing...</Typography>
        </HStack>
      );
    }

    return (
      <VStack w="100%" alignItems="start" spacing="8px" h="100%" minH="44px">
        {isReadOnly ? (
          <VStack p="8px" alignItems="start">
            <Typography color={isAI && !dataPointPerYear?.value ? 'text.hint' : ''} variant="body">
              {dataPointPerYear?.value ? dataPointPerYear.value : isAI ? 'Answer' : 'N/A'}
            </Typography>
          </VStack>
        ) : (
          <AutoResizeTextarea
            minH={hasRef ? 'unset' : '56px'}
            variant="ghost"
            ref={hasRef ? textareaRef : undefined}
            borderRadius="6px"
            lineHeight="20px"
            size="md"
            placeholder={placeholder ?? 'Write your answer'}
            value={inputValue ?? ''}
            opacity={answer?.hasOptedOut ? 0.4 : 1}
            onChange={handleChange}
            onClick={(e) => e.stopPropagation()}
            onBlur={() => setHasStartedTyping(false)}
          />
        )}
        {isIrrelevantResponse && <AIInfoWarning />}
        {showAvailableSummary && (
          <AISummaryAvailable
            metric={metric}
            setRowData={setRowData}
            metricAnswerData={metricAnswerData}
          />
        )}
      </VStack>
    );
  }
);

const ChoiceMetricInput = ({
  metric,
  companyReportingUnitId,
}: {
  metric: MetricsTableData['metric'];
  companyReportingUnitId?: string;
}) => {
  const options = useMemo(
    () =>
      metric.choiceSet?.choices.map((choice) => ({
        label: choice.title,
        value: choice.reference,
      })) ?? [],
    [metric]
  );

  const { onDatapointChange, dataPointPerYear, answer } = useGetDatapointValues(
    {
      metric,
    },
    companyReportingUnitId
  );

  const choices = useMemo(
    () => dataPointPerYear?.datapointChoices.map((choice) => choice.choiceRef),
    [dataPointPerYear]
  );

  const handleChangeAnswer = (newChoices: string[] | undefined, value?: string) => {
    return onDatapointChange({
      value: value ?? dataPointPerYear?.value ?? '',
      hasOptedOut: answer?.hasOptedOut ?? false,
      optOutReason: answer?.optOutReason ?? '',
      dp: dataPointPerYear ?? { timeframe: TimePeriodsEnums.year },
      choices: newChoices,
    });
  };

  const [inputValue, setInputValue] = useState(dataPointPerYear?.value ?? '');

  useEffect(() => {
    setInputValue(dataPointPerYear?.value ?? '');
  }, [dataPointPerYear?.value]);

  const debounceSave = useCallback(
    debounce((newChoices: string[] | undefined, value?: string) => {
      onDatapointChange({
        value: value ?? dataPointPerYear?.value ?? '',
        hasOptedOut: answer?.hasOptedOut ?? false,
        optOutReason: answer?.optOutReason ?? '',
        dp: dataPointPerYear ?? { timeframe: TimePeriodsEnums.year },
        choices: newChoices,
      });
    }, 1000),
    [onDatapointChange, answer, dataPointPerYear]
  );

  return (
    <VStack alignItems="start" width="100%" spacing="2px">
      <MenuSelector
        isSingleSelect={metric.metricType === QuestionType_Enum_.SingleChoice_}
        options={options}
        handleChange={handleChangeAnswer}
        data={choices}
      />
      <AutoResizeTextarea
        variant="ghost"
        borderRadius="6px"
        lineHeight="20px"
        size="md"
        mb="4px"
        placeholder="Write your answer"
        value={inputValue ?? ''}
        onChange={({ target }) => {
          setInputValue(target.value);
          debounceSave(choices, target.value);
        }}
        onClick={(e) => e.stopPropagation()}
      />
    </VStack>
  );
};

const BooleanMetricInput = ({
  metric,
  companyReportingUnitId,
  setRowData,
  isReadOnly = false,
}: {
  metric: MetricsTableData['metric'];
  companyReportingUnitId?: string;
  setRowData: (
    param: (MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] }) | undefined
  ) => void;
  isReadOnly?: boolean;
}) => {
  const { onDatapointChange, dataPointPerYear, answer, loading } = useGetDatapointValues(
    {
      metric,
    },
    companyReportingUnitId
  );

  const [booleanAnswer, setBooleanAnswer] = useState<boolean | undefined>();

  const [hasManuallyChanged, setHasManuallyChanged] = useState<boolean>(false);

  const handleChangeAnswer = (asnwer: boolean | undefined) => {
    return onDatapointChange({
      value: dataPointPerYear?.value ?? '',
      factValue: booleanToText(asnwer),
      hasOptedOut: answer?.hasOptedOut ?? false,
      optOutReason: answer?.optOutReason ?? '',
      dp: dataPointPerYear ?? { timeframe: TimePeriodsEnums.year },
    });
  };

  const dpBooleanAnswer = useMemo(
    () => textToBoolean(dataPointPerYear?.factValue ?? ''),
    [dataPointPerYear]
  );

  useEffect(() => {
    setBooleanAnswer(dpBooleanAnswer);
  }, [dataPointPerYear]);

  useEffect(() => {
    if (hasManuallyChanged) {
      handleChangeAnswer(booleanAnswer);
      setHasManuallyChanged(false);
    }
  }, [booleanAnswer]);

  const handleManualChange = (newAnswer: boolean | undefined) => {
    setHasManuallyChanged(true);
    setBooleanAnswer(newAnswer);
  };

  if (loading) {
    return <Skeleton height="20px" width="100%" />;
  }

  return (
    <>
      {isReadOnly ? (
        <VStack p="8px" alignItems="start">
          {dataPointPerYear?.factValue && (
            <Typography variant="body">{dataPointPerYear?.factValue ?? 'N/A'}</Typography>
          )}
          <Typography variant="body">{dataPointPerYear?.value ?? 'N/A'}</Typography>
        </VStack>
      ) : (
        <VStack alignItems="start" w="100%">
          <BooleanSelector answer={booleanAnswer} handleChangeAnswer={handleManualChange} />
          <LongTextMetricInput
            metric={metric}
            companyReportingUnit={companyReportingUnitId}
            setRowData={setRowData}
            hasRef={false}
          />
        </VStack>
      )}
    </>
  );
};

const AdditionalRowInput = ({
  metric,
  companyReportingUnit,
  placement,
}: {
  metric: MetricsTableData['metric'];
  companyReportingUnit: string | undefined;
  placement: string;
}) => {
  const [inputValue, setInputValue] = useState<string | null>();
  const [hasStartedTyping, setHasStartedTyping] = useState<boolean>(false);

  const { onDatapointChange, answer, dataPointPerYear } = useGetDatapointValues(
    {
      metric,
    },
    companyReportingUnit ?? ''
  );

  const data = useMemo(
    () =>
      placement === 'before'
        ? dataPointPerYear?.metadata?.before
        : dataPointPerYear?.metadata?.after,
    [placement, dataPointPerYear]
  );

  useEffect(() => {
    if (!hasStartedTyping) setInputValue(data?.text ?? null);
  }, [data]);

  const debounceSave = useCallback(
    debounce((newValue) => {
      const metadata = {
        ...dataPointPerYear?.metadata,
        [placement]: {
          ...(data ?? {}),
          text: newValue,
        },
      };
      onDatapointChange({
        value: dataPointPerYear?.value ?? '',
        factValue: dataPointPerYear?.factValue,
        hasOptedOut: answer?.hasOptedOut ?? false,
        optOutReason: answer?.optOutReason ?? '',
        dp: dataPointPerYear ?? { timeframe: TimePeriodsEnums.year },
        metadata: metadata,
      });
    }, 1000),
    [onDatapointChange, dataPointPerYear]
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value === '' ? null : event.target.value;
    setInputValue(newValue);
    debounceSave(newValue);
    setHasStartedTyping(true);
  };

  if (data?.isVisible !== true) {
    return <></>;
  }

  return (
    <AutoResizeTextarea
      value={inputValue ?? ''}
      onChange={handleInputChange}
      minH="56px"
      variant="ghost"
      borderRadius="6px"
      lineHeight="20px"
      size="md"
      placeholder="Write additional information (optional)"
      opacity={answer?.hasOptedOut ? 0.4 : 1}
      onClick={(e) => e.stopPropagation()}
    />
  );
};

export const NarrativeMetricsInput = ({
  metric,
  setRowData,
  rowData,
  companyReportingUnit,
  isReadOnly,
  generatedAnswers,
  answersData,
  isGeneratingAnswers,
  isAI,
  isAdditionalInfo,
  additionalInfoPlacement,
}: {
  metric: AggregatedMetricsTableData['metric'];
  rowData?: MetricsTableData;
  setRowData: (param: MetricsTableData | undefined) => void;
  isReadOnly: boolean;
  companyReportingUnit: string;
  answersData?: AggregatedQualitativeAnswers;
  generatedAnswers?: GeneratedAnswer[];
  isGeneratingAnswers?: boolean;
  isAI: boolean;
  isAdditionalInfo?: boolean;
  additionalInfoPlacement?: string;
}) => {
  const isBooleanMetric = useMemo(() => metric?.metricType === QuestionType_Enum_.YesNo_, [metric]);
  const hasChoice = useMemo(
    () =>
      metric?.metricType === QuestionType_Enum_.SingleChoice_ ||
      metric?.metricType === QuestionType_Enum_.MultipleChoice_,
    [metric]
  );

  const canInputAnswer = useMemo(
    () => !metric?.childrenMetrics?.length || isBooleanMetric || isAdditionalInfo,
    [metric, isBooleanMetric, isAdditionalInfo]
  );
  const generatedAnswer = useMemo(
    () => generatedAnswers?.find((a) => a.metricRef === metric.reference),
    [generatedAnswers]
  );
  return (
    <Box height="100%">
      {canInputAnswer &&
        (isBooleanMetric ? (
          <BooleanMetricInput
            metric={metric}
            setRowData={setRowData}
            companyReportingUnitId={companyReportingUnit}
            isReadOnly={isReadOnly}
          />
        ) : hasChoice ? (
          <ChoiceMetricInput metric={metric} companyReportingUnitId={companyReportingUnit} />
        ) : isAdditionalInfo ? (
          <AdditionalRowInput
            metric={metric}
            companyReportingUnit={companyReportingUnit}
            placement={additionalInfoPlacement ?? 'before'}
          />
        ) : (
          <LongTextMetricInput
            metric={metric}
            companyReportingUnit={companyReportingUnit}
            rowData={rowData}
            setRowData={setRowData}
            hasRef={false}
            isGeneratingAnswers={isGeneratingAnswers}
            answersData={answersData}
            generatedAnswer={generatedAnswer}
            isReadOnly={isReadOnly}
            isAI={isAI}
          />
        ))}
    </Box>
  );
};
