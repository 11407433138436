import { Box, HStack, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import {
  BiodiversityIcon,
  CircularEconomyIcon,
  ClimateAdaptationIcon,
  ClimateMitigationIcon,
  PollutionPreventionIcon,
  WaterIcon,
} from 'Tokens/Icons/ObjectiveIcons';
import React from 'react';

export type VariantType = typeof OBJECTIVE_VARIANTS;
export type ObjectiveStatusType = typeof STATUS_VARIANTS;
export type TagStatusType = typeof TAG_STATUS;

type ObjectivesTilesType = {
  variant: keyof VariantType;
  objectiveStatus: keyof ObjectiveStatusType;
  tagStatus?: string;
};

export const OBJECTIVE_VARIANTS = {
  mitigation: {
    icon: ClimateMitigationIcon,
    title: 'Climate mitigation',
  },
  water: {
    icon: WaterIcon,
    title: 'Water',
  },
  pollution: {
    icon: PollutionPreventionIcon,
    title: 'Pollution prevention',
  },
  adaptation: {
    icon: ClimateAdaptationIcon,
    title: 'Climate adaptation',
  },
  circular: {
    icon: CircularEconomyIcon,
    title: 'Circular economy',
  },
  biodiversity: {
    icon: BiodiversityIcon,
    title: 'Biodiversity',
  },
};

export const STATUS_VARIANTS = {
  SC: {
    description: 'Substantial contribution',
    borderColor: '',
    bgColor: 'bg.info',
    iconColor: 'text.info',
    iconBgColor: 'bg.info',
  },
  DNSH: {
    description: 'Do no significant harm',
    borderColor: '',
    bgColor: 'bg.muted',
    iconColor: 'text.muted',
    iconBgColor: 'bg.unknown',
  },
  NotRelevant: {
    description: 'N/A - Not relevant',
    borderColor: 'border.decorative',
    bgColor: 'bg.default',
    iconColor: 'text.muted',
    iconBgColor: 'bg.unknown',
  },
};

export const TAG_STATUS = {
  Default: {
    status: '',
  },
  Enabling: {
    status: 'Enabling',
  },
  Transitional: {
    status: 'Transitional',
  },
  CanBeEnabling: {
    status: 'Can be enabling',
  },
  CanBeTransitional: {
    status: 'Can be transitional',
  },
};

export const ObjectivesTiles = ({
  variant,
  objectiveStatus,
  tagStatus,
  size,
}: ObjectivesTilesType & { size?: 'sm' | 'md' }) => {
  const { icon: IconComponent, title } = OBJECTIVE_VARIANTS[variant];
  const { description, bgColor, iconColor, iconBgColor, borderColor } =
    STATUS_VARIANTS[objectiveStatus];

  const isSmall = size === 'sm';

  return (
    <HStack
      width={isSmall ? '284px' : '312px'}
      height={isSmall ? '40px' : '56px'}
      padding={isSmall ? '6px' : '10px'}
      gap={isSmall ? '6px' : '8px'}
      alignItems="start"
      borderRadius="8px"
      bgColor={bgColor}
      border={borderColor ? '1px' : ''}
      borderColor={borderColor ? borderColor : ''}
    >
      <VStack>
        <Box backgroundColor={iconBgColor} borderRadius="50%" w="24px" h="24px" position="relative">
          <IconComponent
            boxSize="16px"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%,-50%)"
            color={iconColor}
          />
        </Box>
      </VStack>
      <VStack gap="0px" alignItems="start" width="100%">
        <Typography
          variant={isSmall ? 'detailStrong' : 'bodyStrong'}
          color="text.default"
          whiteSpace="nowrap"
        >
          {title}
        </Typography>
        <Typography variant={isSmall ? 'micro' : 'detail'} color="text.muted" whiteSpace="nowrap">
          {description}
        </Typography>
      </VStack>
      <VStack alignItems="start">
        <Typography
          variant={isSmall ? 'microStrong' : 'detailStrong'}
          color="text.info"
          whiteSpace="nowrap"
        >
          {tagStatus}
        </Typography>
      </VStack>
    </HStack>
  );
};
