import { Box, HStack, VStack, Wrap } from '@chakra-ui/react';
import { Activity } from 'models';
import { Drawer, TruncatedTagList } from 'Molecules';
import { OBJECTIVE_VARIANTS, ObjectivesTiles, VariantType } from 'Molecules/ObjectivesTiles';
import React from 'react';
import { Typography } from 'Tokens';
import { useTranslation } from 'utils/translation';
import {
  objectiveExpressionTag,
  useActivityTagObjectives,
  useObjectiveStatusHelpers,
} from './Activities.hooks';

type ActivityDetailsProps = {
  activity?: Activity;
  onClose: () => void;
  isOpen: boolean;
};

export function ActivityNaceCodes({
  activity,
  truncate = false,
}: {
  activity: Activity;
  truncate?: boolean;
}) {
  const codes = activity.naceCodes.map((code) => code.code);
  return (
    <Wrap>
      <TruncatedTagList tags={codes} lines={truncate ? 1 : 3} size="sm" />
    </Wrap>
  );
}

export function ActivityDescription({ activity }: { activity: Activity }) {
  const { getObjectiveStatusKey } = useObjectiveStatusHelpers(activity);
  const { data } = useActivityTagObjectives(
    activity?.referenceNumber ?? '',
    activity?.currentQuestionSetVersion ?? 1
  );

  const { t } = useTranslation('common');
  return (
    <VStack alignItems="start" maxWidth="600px">
      <VStack alignItems="start" spacing="12px" pb="24px">
        <Typography variant="h2">Objectives</Typography>
        <VStack spacing="8px">
          <HStack spacing="8px" flexWrap="wrap" width="632px">
            {(
              Object.entries(OBJECTIVE_VARIANTS) as [
                keyof VariantType,
                VariantType[keyof VariantType],
              ][]
            ).map(([key]) => {
              return (
                <ObjectivesTiles
                  variant={key}
                  objectiveStatus={getObjectiveStatusKey(key)}
                  tagStatus={
                    key === data.tagObjective ? objectiveExpressionTag(data.tagExpression) : ''
                  }
                />
              );
            })}
          </HStack>
        </VStack>
        <Typography variant="body" color="text.default">
          <strong style={{ fontWeight: 500 }}> When activity is enabling </strong> and if the
          company’s activity is related to oil and gas production it won’t be eligible.
        </Typography>
      </VStack>
      <VStack alignItems="start" spacing="16px" paddingBottom="24px">
        <Typography variant="h2">EU guidance</Typography>
        <VStack alignItems="start" spacing="4px">
          <Typography variant="body">Description:</Typography>
          <Box
            backgroundColor="bg.muted"
            padding="12px 20px"
            borderLeft="3px solid"
            borderColor="bg.accent"
            width="632px"
          >
            <Typography
              color="text.default"
              fontFamily="PT Serif"
              fontWeight="400"
              fontSize="15px"
              lineHeight="22px"
            >
              <Box
                dangerouslySetInnerHTML={{
                  __html: activity.description || '',
                }}
              />
            </Typography>
          </Box>
          {activity.descriptionSource && (
            <HStack gap="3px">
              <Typography variant="detail">Source:</Typography>
              <Typography color="text.selected" variant="detail">
                <Box
                  dangerouslySetInnerHTML={{
                    __html: activity.descriptionSource || '',
                  }}
                />
              </Typography>
            </HStack>
          )}
        </VStack>
        {activity.additionalGuidance && (
          <VStack alignItems="start" spacing="4px">
            <Typography variant="body">Additional guidance:</Typography>
            <Box
              backgroundColor="bg.muted"
              padding="12px 20px"
              borderLeft="3px solid"
              borderColor="bg.accent"
              width="632px"
            >
              <Typography
                color="text.default"
                fontFamily="PT Serif"
                fontWeight="400"
                fontSize="15px"
                lineHeight="22px"
              >
                <Box
                  dangerouslySetInnerHTML={{
                    __html: activity.additionalGuidance || '',
                  }}
                />
              </Typography>
            </Box>
            {activity.guidanceSource && (
              <HStack gap="3px">
                <Typography variant="detail">Source:</Typography>
                <Typography color="text.selected" variant="detail">
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: activity.guidanceSource || '',
                    }}
                  />
                </Typography>
              </HStack>
            )}
          </VStack>
        )}
        <VStack alignItems="start" spacing="8px">
          <Typography variant="body">{t('common:naceCodes')}</Typography>
          <Box>
            <ActivityNaceCodes activity={activity} />
          </Box>
          {activity.guidanceSource && (
            <HStack gap="3px">
              <Typography variant="detail">Source:</Typography>
              <Typography color="text.selected" variant="detail">
                <Box
                  dangerouslySetInnerHTML={{
                    __html: activity.descriptionSource || '',
                  }}
                />
              </Typography>
            </HStack>
          )}
        </VStack>
      </VStack>
      {activity.celsiaComment && (
        <VStack alignItems="start" spacing="4px" paddingBottom="40px">
          <Typography variant="h2" color="text.default">
            Celsia comment
          </Typography>
          <Typography color="text.default" variant="body">
            <Box
              dangerouslySetInnerHTML={{
                __html: activity.celsiaComment || '',
              }}
            />
          </Typography>
        </VStack>
      )}
    </VStack>
  );
}

export const ActivityDetails = ({ activity, isOpen, onClose }: ActivityDetailsProps) => {
  return (
    <Drawer
      header={
        <Typography variant="h2" color="text.default">
          {activity?.name}
        </Typography>
      }
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
    >
      {activity && <ActivityDescription activity={activity} />}
    </Drawer>
  );
};
