import {
  IconBuildingFactory,
  IconChristmasTree,
  IconFish,
  IconRecycle,
  IconTemperature,
  IconUmbrella,
} from '@tabler/icons-react';
import { tablerIcon } from './Icons';

export const ClimateMitigationIcon = tablerIcon(IconTemperature);
export const WaterIcon = tablerIcon(IconFish);
export const PollutionPreventionIcon = tablerIcon(IconBuildingFactory);

export const ClimateAdaptationIcon = tablerIcon(IconUmbrella);
export const CircularEconomyIcon = tablerIcon(IconRecycle);
export const BiodiversityIcon = tablerIcon(IconChristmasTree);
