import { Box, HStack, Checkbox } from '@chakra-ui/react';
import { ColumnDefResolved } from '@tanstack/react-table';
import { Avatar, IconButton, TruncatableText } from 'Atoms';
import { NestedTable, TableData } from 'Molecules/NestedTable';
import { Typography } from 'Tokens';
import { ArrowNarrowRightIcon, ChevronDownIcon, ChevronRightIcon } from 'Tokens/Icons/Direction';
import { AIIcon } from 'Tokens/Icons/Function';
import {
  GeneratedSummaryStatus,
  MetricsTableData,
  SelectedMetric,
  areArraysOfObjectsEqual,
} from 'containers/Esrs';
import { mapUnitToCompanyCurrency } from 'containers/Esrs/utils';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FrequencyEnums, TimePeriods, TimePeriodsEnums } from '../../Requirement';
import {
  AggregatedMetricsTableData,
  AggregatedQualitativeAnswers,
} from '../AggregatedMetrics/AggregatedMetrics.hooks';
import { isEqual } from 'lodash';
import { QuestionType_Enum_, User } from 'models';
import { GeneratedAnswer } from '../MetricAI';
import { MetricOwnerAvatar } from '../InputTable/MetricOwnerSelect';
import { DataCollectionLevel } from 'containers/Esrs/pieces/DataCollection';
import { CommentsCounter } from '../AggregatedMetrics/AggregatedMetricsUtils';
import { getMetricRefNumber } from '../Metrics.hooks';
import { MetricsAttachmentsLabel } from '../InputTable/MetricAttachmentsLabel';
import { MetricCheckboxSelector } from './MetricsTableComponents/CheckboxSelector/CheckboxSelector';
import { NarrativeMetricsInput } from './MetricsTableComponents/InputFields/NarrativeInputs/NarrativeInputs';
import { QuantitativeMetricsInput } from './MetricsTableComponents/InputFields/QuantitativeInputs/QuantitativeInputs';
import { LastEditedBy } from './MetricsTableComponents/LastEdited/LastEdited';
import { getLastEditedData } from './MetricsTableComponents/LastEdited/LastEdited.hooks';
import { MetricMdrmStatus } from './MetricsTableComponents/MetricMDRMStatus/MetricMDRMStatus';
import { MetricProgress } from './MetricsTableComponents/MetricProgress/MetricProgress';
import { MetricRowTitle } from './MetricsTableComponents/MetricRowTitle/MetricRowTitle';
import { MetricYear } from './MetricsTableComponents/MetricYear/MetricYear';
import { GoToSource } from './MetricsTableComponents/GoToSource/GoToSource';
import { AssessableMetrics } from '../Metrics';
import { flattenNarrativeMetrics } from '../Metrics.utils';
import { AnswerApprovalCell } from './MetricsTable.utils';
import {
  AdditionalInfoButton,
  RemoveAdditionalInfo,
} from './MetricsTableComponents/AdditionalInfoModals/AdditionalInfoModals';

export const MetricsTable = ({
  metrics,
  selectedQuarter,
  onDrawerOpen,
  setSelectedMetric,
  isOverview = false,
  areMetricsYearly,
  isGroup,
  currency,
  onOpen,
  materialStandardId,
  companyReportingUnit,
  esrsAssessmentProjectLeader,
  rowData,
  setRowData,
  pageSize = 5,
  allMetrics,
  setNumberOfRows,
  isMetricDr,
  selectedNumericMetrics,
  setSelectedNumericMetrics,
  selectedNarrativeMetrics,
  setSelectedNarrativeMetrics,
  isAI = false,
  answersData,
  setIsComments,
  generatedAnswers,
  setSelectedRow,
  onApprovalWarningModalOpen,
  isGeneratingAnswers,
  setGeneratedAnswers,
  populateQuantitativeAnswers,
  populateNarrativeAnswers,
  checkboxesDisabled = true,
  generatedSummaryStatus,
  isNarrative,
  isReadOnly,
  isBusinessUnit,
}: {
  metrics: AggregatedMetricsTableData[];
  selectedQuarter: TimePeriods;
  onDrawerOpen: () => void;
  setSelectedMetric: (param: SelectedMetric) => void;
  isOverview?: boolean;
  areMetricsYearly: boolean;
  isGroup: boolean;
  currency: string;
  answersData?: AggregatedQualitativeAnswers;
  onOpen: () => void;
  materialStandardId: string;
  companyReportingUnit?: string;
  onApprovalWarningModalOpen?: () => void;
  isGeneratingAnswers?: boolean;
  isCompanyLevel?: boolean;
  esrsAssessmentProjectLeader?: Partial<User>;
  rowData?: MetricsTableData;
  setSelectedRow?: React.Dispatch<React.SetStateAction<string>>;
  setRowData: (
    param: (MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] }) | undefined
  ) => void;
  pageSize?: number;
  allMetrics?: MetricsTableData[];
  setNumberOfRows: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  isMetricDr: boolean;
  selectedNumericMetrics?: MetricsTableData[];
  setSelectedNumericMetrics?: Dispatch<SetStateAction<MetricsTableData[]>>;
  selectedNarrativeMetrics?: AssessableMetrics;
  setSelectedNarrativeMetrics?: Dispatch<SetStateAction<AssessableMetrics>>;
  isAI?: boolean;
  setIsComments: (val: boolean) => void;
  generatedAnswers?: GeneratedAnswer[];
  setGeneratedAnswers?: Dispatch<SetStateAction<GeneratedAnswer[]>>;
  populateQuantitativeAnswers?: (
    generatedAnswers: GeneratedAnswer[],
    numericMetrics: MetricsTableData[]
  ) => void;
  generatedSummaryStatus?: GeneratedSummaryStatus;
  populateNarrativeAnswers?: (
    generatedAnswers: GeneratedAnswer[],
    numericMetrics: AssessableMetrics
  ) => void;
  checkboxesDisabled?: boolean;
  isNarrative?: boolean;
  isReadOnly?: boolean;
  isBusinessUnit?: boolean;
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const urlDatapointId = useMemo(() => searchParams.get('datapointId'), [searchParams]);
  const openDrawer = new URLSearchParams(location.search).get('openDrawer');

  const showProgress = useMemo(() => {
    if (isNarrative) return false;

    const dataCollection = metrics[0].metric.materialMetrics[0].dataCollection;
    const frequency = metrics[0].metric.materialMetrics[0].frequency;

    if (isBusinessUnit) return frequency === FrequencyEnums.quarterly;
    return (
      (dataCollection === DataCollectionLevel.subsidiaries && isGroup) ||
      (dataCollection === DataCollectionLevel.reportingUnits && !isGroup) ||
      frequency === FrequencyEnums.quarterly
    );
  }, [metrics]);

  const progressLabel = useMemo(() => {
    return metrics[0].metric.materialMetrics[0].dataCollection ===
      DataCollectionLevel.subsidiaries && isGroup
      ? 'Subsidiaries'
      : metrics[0].metric.materialMetrics[0].dataCollection === DataCollectionLevel.reportingUnits
        ? 'Business units'
        : 'Quarters';
  }, [metrics]);

  const progressTooltip = useMemo(() => {
    const dataCollection = metrics[0].metric.materialMetrics[0].dataCollection;
    if (dataCollection === DataCollectionLevel.subsidiaries && isGroup) {
      return 'This data point is collected from subsidiaries. The progress indicates whether all subsidiaries have added their answers.';
    } else if (dataCollection === DataCollectionLevel.reportingUnits) {
      return 'This data point is collected from business units. The progress indicates whether all business units have added their answers.';
    }
    return 'This data point is collected quarterly. The progress indicates whether data is collected from all quarters or only a few.';
  }, [metrics]);

  const handleRowClick = (row: MetricsTableData, isComments: boolean) => {
    if (!row.referenceToSource) {
      setSelectedRow?.(row.metric.reference);
      if (openDrawer && !!urlDatapointId) navigate(pathname.split('?')[0]);
      if (generatedSummaryStatus?.isApproved === false) {
        if (onApprovalWarningModalOpen) onApprovalWarningModalOpen();
      } else {
        if (isEqual(rowData, row)) {
          setRowData(undefined);
        } else {
          setRowData({
            metric: row.metric,
            sourceData: answersData?.find((ans) => ans.metricRef === row.metric.reference),
          });
          if (isComments) {
            setIsComments(true);
          } else setIsComments(false);
        }
      }
    }
  };
  const flattenedMetrics = useMemo(
    () => flattenNarrativeMetrics(metrics.map((m) => m.metric)),
    [metrics]
  );

  const allMetricsChecked = useMemo(
    () =>
      flattenedMetrics.every((metric) =>
        selectedNarrativeMetrics?.some((row) => row.reference === metric.reference)
      ),
    [selectedNarrativeMetrics, flattenedMetrics]
  );

  const someMetricsChecked = useMemo(
    () =>
      !allMetricsChecked &&
      flattenedMetrics.some((metric) =>
        selectedNarrativeMetrics?.some((row) => row.reference === metric.reference)
      ),
    [selectedNarrativeMetrics, flattenedMetrics, allMetricsChecked]
  );

  const handleCheckAll = useCallback(() => {
    if (allMetricsChecked) {
      setSelectedNarrativeMetrics?.([]);
    } else {
      setSelectedNarrativeMetrics?.(flattenedMetrics);
    }
  }, [allMetricsChecked, flattenedMetrics, setSelectedNarrativeMetrics]);

  const nestedColumns: ColumnDefResolved<TableData<AggregatedMetricsTableData>, any>[] | undefined =
    useMemo(
      () => [
        {
          header: '',
          meta: {
            width: '32px',
            padding: '16px 8px',
            verticalAlign: 'top',
            headerComponent: isNarrative && (
              <Checkbox
                isDisabled={checkboxesDisabled}
                isChecked={allMetricsChecked}
                isIndeterminate={someMetricsChecked}
                onChange={handleCheckAll}
              />
            ),
          },
          accessorKey: 'selectedRows',
          cell: ({ row }) => {
            if (isNarrative) {
              const isParent = row.original.metric.childrenMetrics.length > 0;
              const isChecked = isParent
                ? row.original.metric.childrenMetrics.every((c) =>
                    selectedNarrativeMetrics?.some((r) => r.reference === c.childMetric?.reference)
                  )
                : selectedNarrativeMetrics?.some(
                    (met) => met.reference === row.original.metric.reference
                  ) ?? false;

              const isIntermediate =
                isParent && !isChecked
                  ? row.original.metric.childrenMetrics.some((c) =>
                      selectedNarrativeMetrics?.some(
                        (r) => r.reference === c.childMetric?.reference
                      )
                    )
                  : false;

              const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                if (isParent) {
                  if (e.target.checked) {
                    const childrenMetrics = (row.original.metric.childrenMetrics ?? []).map(
                      (c) => c.childMetric
                    ) as AssessableMetrics;
                    setSelectedNarrativeMetrics?.([
                      ...(selectedNarrativeMetrics ?? []),
                      ...childrenMetrics,
                    ]);
                  } else {
                    const childrenReferences = (row.original.metric.childrenMetrics ?? []).map(
                      (c) => c.childMetric?.reference
                    );
                    setSelectedNarrativeMetrics?.(
                      (selectedNarrativeMetrics ?? []).filter(
                        (r) => !childrenReferences.includes(r.reference)
                      )
                    );
                  }
                } else {
                  if (e.target.checked) {
                    setSelectedNarrativeMetrics?.([
                      ...(selectedNarrativeMetrics ?? []),
                      row.original.metric as AssessableMetrics[number],
                    ]);
                  } else {
                    setSelectedNarrativeMetrics?.(
                      (selectedNarrativeMetrics ?? []).filter(
                        (r) => r.reference !== row.original.metric.reference
                      )
                    );
                  }
                }
              };
              return (
                <Checkbox
                  isDisabled={checkboxesDisabled}
                  isChecked={isChecked}
                  isIndeterminate={isIntermediate}
                  onChange={handleCheckboxChange}
                />
              );
            }
            return (
              selectedNumericMetrics &&
              setSelectedNumericMetrics && (
                <MetricCheckboxSelector
                  row={row.original}
                  selectedRows={selectedNumericMetrics}
                  setSelectedRows={setSelectedNumericMetrics}
                  isDisabled={checkboxesDisabled}
                />
              )
            );
          },
        },
        {
          header: 'Data point',
          meta: {
            width: isNarrative ? '40%' : '30%',
            verticalAlign: isNarrative ? 'top' : 'center',
            padding: isNarrative ? '0px 8px' : undefined,
          },
          accessorKey: 'metric',
          cell: ({ row }) => {
            if (row.original.isAdditionalInfoButton) {
              return (
                <Box pt="6px" pl={`${row.depth * 24}px`}>
                  <AdditionalInfoButton
                    metric={row.original.metric}
                    companyReportingUnit={companyReportingUnit ?? ''}
                  />
                </Box>
              );
            }
            return row.original.referenceToSource ? (
              <GoToSource
                row={row}
                filteredMetrics={allMetrics ?? []}
                setNumberOfRows={setNumberOfRows}
              />
            ) : (
              <HStack
                spacing="8px"
                height="fit-content"
                id={
                  !row.subRows.some((subrow) => subrow.original.referenceToSource)
                    ? row.original.metric.reference
                    : undefined
                }
              >
                {row.getCanExpand() && !isNarrative && (
                  <Box onClick={(e) => e.stopPropagation()}>
                    <IconButton
                      variant={'ghost'}
                      size="md"
                      onClick={row.getToggleExpandedHandler()}
                      aria-label="expand"
                      icon={row.getIsExpanded() ? <ChevronDownIcon /> : <ChevronRightIcon />}
                    />
                  </Box>
                )}
                <MetricRowTitle
                  companyStandardId={materialStandardId}
                  rowData={rowData}
                  row={row}
                  onClick={() => handleRowClick(row.original, false)}
                  isNarrative={isNarrative}
                />
              </HStack>
            );
          },
        },
        {
          header: 'Ref.',
          meta: {
            width: '88px',
            verticalAlign: isNarrative ? 'top' : 'center',
            padding: '14px 8px',
          },
          accessorKey: 'reference',
          cell: ({ row }) =>
            row.original.isAdditionalInfo ? (
              <Typography variant="body">_</Typography>
            ) : (
              !row.original.referenceToSource &&
              !row?.original.isAdditionalInfoButton &&
              !row.original.locked && (
                <TruncatableText text={getMetricRefNumber(row.original.metric)} />
              )
            ),
        },
        {
          header: isNarrative
            ? 'Answer'
            : selectedQuarter !== TimePeriodsEnums.year
              ? selectedQuarter
              : "Year's total",

          meta: {
            width: (() => {
              if (!showProgress && !isMetricDr) return '38%';
              if (showProgress) return '20%';
              return isNarrative ? '41%' : '30%';
            })(),
            verticalAlign: isNarrative ? 'top' : 'center',
            padding: isNarrative ? '6px 8px' : undefined,
          },
          accessorKey: 'yearly',
          cell: ({ row }) => {
            const { metric } = row.original;
            const materialMetric = metric.materialMetrics[0];
            const { dataCollection, frequency } = materialMetric;

            const showMetricProgress =
              dataCollection === DataCollectionLevel.reportingUnits ||
              (dataCollection === DataCollectionLevel.subsidiaries && isGroup) ||
              frequency === FrequencyEnums.quarterly;

            if (metric.metricType !== QuestionType_Enum_.Decimal_) {
              if (!row.original.locked) {
                return (
                  <NarrativeMetricsInput
                    metric={metric}
                    companyReportingUnit={companyReportingUnit ?? ''}
                    isAI={isAI}
                    isAdditionalInfo={row.original.isAdditionalInfo}
                    additionalInfoPlacement={row.original.additionalInfoPlacement}
                    isReadOnly={isReadOnly ?? false}
                    isGeneratingAnswers={isGeneratingAnswers}
                    setRowData={setRowData}
                    answersData={answersData}
                    generatedAnswers={generatedAnswers}
                  />
                );
              }
            } else {
              if (!row.original.referenceToSource && !row.original.locked) {
                return (
                  <HStack justifyContent="start" alignItems="center">
                    <Box w="50%">
                      <QuantitativeMetricsInput
                        metricRow={row.original}
                        metrics={metrics}
                        isNarrativeTable={isNarrative}
                        assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
                        companyAssessmentId={materialStandardId}
                        companyReportingUnit={companyReportingUnit ?? ''}
                        companyStandardId={materialStandardId}
                        isGroup={isGroup}
                        isBusinessUnit={isBusinessUnit}
                        setRowData={setRowData}
                        selectedQuarter={selectedQuarter}
                      />
                    </Box>
                    {isNarrative && (
                      <HStack alignItems="center" justifyContent="space-between" w="50%">
                        <TruncatableText
                          w="88px"
                          text={mapUnitToCompanyCurrency(
                            metric.unitOfMeasurement ?? 'NA',
                            currency
                          )}
                        />
                        {showMetricProgress && (
                          <Box minW="84px" p="6px 8px">
                            <MetricProgress
                              row={row.original}
                              answersData={answersData}
                              isGroup={isGroup}
                              companyStandardId={materialStandardId}
                              companyReportingUnit={companyReportingUnit}
                            />
                          </Box>
                        )}
                      </HStack>
                    )}
                  </HStack>
                );
              }
            }
          },
        },
        {
          header: 'Unit',
          meta: {
            width: '88px',
          },
          accessorKey: 'unit',
          cell: ({ row }) =>
            !row.original.referenceToSource &&
            !row.original.locked && (
              <TruncatableText
                text={mapUnitToCompanyCurrency(
                  row.original.metric.unitOfMeasurement ?? 'NA',
                  currency
                )}
              />
            ),
        },
        {
          header: 'Answer',
          accessorKey: 'AIAnswer',
          meta: {
            padding: '14px 8px',
            verticalAlign: 'top',
          },
          cell: ({ row }) => {
            const generatedAnswer = generatedAnswers?.find(
              (a) =>
                a.metricRef === row.original.metric.reference &&
                areArraysOfObjectsEqual(a.tags, row.original.tags)
            );

            return (
              !row.original.referenceToSource && (
                <MetricYear
                  row={row.original}
                  companyStandardId={materialStandardId}
                  companyReportingUnit={companyReportingUnit}
                  nestedMetrics={metrics}
                  generatedAnswer={generatedAnswer}
                  isAI={isAI}
                />
              )
            );
          },
        },
        {
          header: 'Progress',
          meta: {
            width: '84px',
            subtitle: progressLabel,
            verticalAlign: 'center',
            tooltip: progressTooltip,
          },
          accessorKey: 'progress',
          cell: ({ row }) =>
            row.original &&
            !row.original.referenceToSource &&
            !row.original.locked && (
              <MetricProgress
                row={row.original}
                answersData={answersData}
                isGroup={isGroup}
                companyStandardId={materialStandardId}
                companyReportingUnit={companyReportingUnit}
              />
            ),
        },
        {
          header: 'MDR-M',
          meta: {
            width: '68px',
            padding: '14px 8px',
            verticalAlign: 'top',
          },
          accessorKey: 'mdr-m',
          cell: ({ row }) =>
            row.original &&
            !row.original.referenceToSource && (
              <MetricMdrmStatus
                row={row.original}
                companyReportingUnit={companyReportingUnit}
                selectedQuarter={selectedQuarter}
                materialStandardId={materialStandardId}
              />
            ),
        },
        {
          header: 'Source',
          meta: {
            width: '10%',
            padding: '14px 8px',
            verticalAlign: 'top',
          },
          accessorKey: 'source',
          cell: ({ row }) => {
            return (
              <MetricsAttachmentsLabel
                row={row.original}
                companyReportingUnitId={companyReportingUnit}
              />
            );
          },
        },
        {
          header: 'Last edited',
          accessorKey: 'lastEdited',
          meta: {
            width: '10%',
            padding: '14px 8px',
            verticalAlign: 'top',
          },
          cell: ({ row }) => {
            const generatedAnswer = generatedAnswers?.find(
              (a) =>
                a.metricRef === row.original.metric.reference &&
                areArraysOfObjectsEqual(a.tags, row.original.tags)
            );
            const isAwaitingApproval =
              generatedAnswer?.answer &&
              generatedAnswer?.status === null &&
              generatedAnswer?.answer !== '-' &&
              generatedAnswer?.answer !== 'NA';

            return (
              !row.original.referenceToSource &&
              (isAwaitingApproval ? (
                <HStack spacing="4px">
                  <Avatar name="AI" size="xs" />
                  <Typography variant="body">{getLastEditedData(new Date()).date}</Typography>
                </HStack>
              ) : (
                <LastEditedBy
                  row={row.original}
                  companyStandardId={materialStandardId}
                  selectedQuarter={selectedQuarter}
                  reportingUnitId={companyReportingUnit ? companyReportingUnit : undefined}
                />
              ))
            );
          },
        },
        {
          header: 'Owner',
          meta: {
            width: '54px',
            verticalAlign: isNarrative ? 'top' : 'center',
            padding: '14px 8px',
          },
          accessorKey: 'ownerId',
          cell: ({ row }) =>
            row.original &&
            !row.original.referenceToSource &&
            !row.original.locked &&
            !row?.original.isAdditionalInfoButton &&
            !row.original.isAdditionalInfo && (
              <MetricOwnerAvatar
                row={row.original}
                selectedQuarter={selectedQuarter}
                companyReportingUnit={companyReportingUnit}
                assessmentProjectLeader={esrsAssessmentProjectLeader}
              />
            ),
        },
        {
          header: '',
          meta: {
            width: '6%',
            verticalAlign: isNarrative ? 'top' : 'center',
            padding: isNarrative ? '10px 8px' : '6px 8px',
          },
          accessorKey: 'actions',
          cell: ({ row }) => {
            return row.original.isAdditionalInfo ? (
              <HStack gap="2px" justifyContent="end">
                <RemoveAdditionalInfo
                  metric={row.original.metric}
                  companyReportingUnit={companyReportingUnit}
                  placement={row.original.additionalInfoPlacement ?? 'before'}
                />
              </HStack>
            ) : (
              row.original &&
                !row.original.referenceToSource &&
                !row.original.locked &&
                !row?.original.isAdditionalInfoButton && (
                  <HStack gap="2px">
                    <CommentsCounter
                      row={row.original}
                      selectedQuarter={selectedQuarter}
                      companyReportingUnit={companyReportingUnit}
                      onClick={() => handleRowClick(row.original, true)}
                    />
                    <IconButton
                      className="metricArrow"
                      aria-label="side-bar"
                      size="sm"
                      variant="ghost"
                      icon={<ArrowNarrowRightIcon />}
                      onClick={() => handleRowClick(row.original, false)}
                    />
                  </HStack>
                )
            );
          },
        },
        {
          // @ts-ignore
          header: () => {
            return (
              <HStack spacing="8px">
                <AIIcon />
                <Typography variant="bodyStrong">Approve</Typography>
              </HStack>
            );
          },
          meta: {
            padding: '10px 8px',
            verticalAlign: 'top',
            headerComponent: (
              <HStack spacing="8px">
                <AIIcon />
                <Typography variant="bodyStrong">Approve</Typography>
              </HStack>
            ),
          },
          accessorKey: 'ai-approval',
          cell: ({ row }) => {
            const generatedAnswer = generatedAnswers?.find(
              (a) =>
                a.metricRef === row.original.metric.reference &&
                areArraysOfObjectsEqual(a.tags, row.original.tags)
            );

            const isGeneratedAnswerValid =
              generatedAnswer?.answer &&
              generatedAnswer.answer !== 'NA' &&
              generatedAnswer.answer !== '-';

            return isNarrative
              ? generatedAnswer && generatedAnswer.answer !== '-' && (
                  <AnswerApprovalCell
                    generatedAnswers={generatedAnswers ?? []}
                    setGeneratedAnswers={setGeneratedAnswers}
                    row={{ metric: row.original.metric as MetricsTableData['metric'], tags: [] }}
                    assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
                    companyReportingUnitId={companyReportingUnit}
                    populateNarrativeAnswers={populateNarrativeAnswers}
                  />
                )
              : !row.original.referenceToSource && isGeneratedAnswerValid && (
                  <AnswerApprovalCell
                    row={row.original}
                    populateQuantitativeAnswers={populateQuantitativeAnswers}
                    generatedAnswers={generatedAnswers ?? []}
                    setGeneratedAnswers={setGeneratedAnswers}
                    companyReportingUnitId={companyReportingUnit}
                    assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
                  />
                );
          },
        },
      ],
      [
        selectedQuarter,
        onDrawerOpen,
        onOpen,
        setSelectedMetric,
        rowData,
        materialStandardId,
        isOverview,
        areMetricsYearly,
        checkboxesDisabled,
        selectedNarrativeMetrics,
        selectedNumericMetrics,
        generatedAnswers,
      ]
    );

  const filteredColumns = useMemo(() => {
    if (!nestedColumns) return [];

    return nestedColumns.filter((col) => {
      const { accessorKey = '' } = col;

      const conditions = [
        !showProgress && accessorKey === 'progress',
        isNarrative && ['unit', 'progress', 'mdr-m'].includes(accessorKey),
        !(isAI && selectedNumericMetrics && setSelectedNumericMetrics) &&
          accessorKey === 'selectedRows',
        isAI && ['unit', 'progress', 'yearly', 'mdr-m'].includes(accessorKey),
        !isAI && ['AIAnswer', 'source', 'lastEdited'].includes(accessorKey),
        !(isAI && !!generatedAnswers?.length) && accessorKey === 'ai-approval',
        !isMetricDr && accessorKey === 'mdr-m',
      ];

      return !conditions.some(Boolean);
    });
  }, [
    nestedColumns,
    selectedQuarter,
    showProgress,
    isNarrative,
    isAI,
    selectedNumericMetrics,
    setSelectedNumericMetrics,
    generatedAnswers,
    isMetricDr,
  ]);

  return (
    <NestedTable<AggregatedMetricsTableData>
      columns={filteredColumns}
      data={metrics}
      expanded={true}
      pageSize={isNarrative ? undefined : pageSize}
      headerVariant="detailStrong"
      withInnerBorder={!isNarrative}
      cellProps={(row) => {
        if (isNarrative) {
          return {
            borderTop:
              !row?.isAdditionalInfoButton &&
              !row?.isAdditionalInfo &&
              (!row?.metric.parentMetrics.length ||
                row?.metric.metricType === QuestionType_Enum_.Decimal_)
                ? '1px solid'
                : 'none',
            borderBottom:
              row?.metric.metricType === QuestionType_Enum_.Decimal_ ? '1px solid' : 'none',
            borderColor: 'border.decorative',
          };
        }
        return {};
      }}
      rowProps={(row) => {
        const generatedAnswer = generatedAnswers?.find(
          (a) => a.metricRef === row?.metric.reference && areArraysOfObjectsEqual(a.tags, row?.tags)
        )?.answer;

        const getBackgroundColor = () => {
          if (row?.locked) return 'bg.disabled';
          if (isNarrative) {
            if (generatedAnswer) {
              return generatedAnswer === '-' ? 'bg.warning' : 'bg.selected';
            }
            return '';
          }

          if (
            isEqual(
              {
                metric: row?.metric,
                sourceData: answersData?.find((ans) => ans.metricRef === row?.metric.reference),
              },
              rowData
            )
          ) {
            return 'bg.hover';
          }
          if (generatedAnswer) {
            if (generatedAnswer === 'NA') {
              return 'bg.warning';
            }
            return 'bg.selected';
          }
          return '';
        };

        return {
          bg: getBackgroundColor(),
          transition: '0.1s',
          _hover:
            !row?.isAdditionalInfoButton && !row?.locked && !row?.isAdditionalInfo
              ? {
                  transition: '0.15s ease-in-out',
                  bg: 'bg.hover',
                  '&:hover .metricTitle': {
                    textDecoration: 'underline',
                    textDecorationColor: 'text.hint',
                    textUnderlineOffset: '2px',
                  },
                  '&:hover .metricArrow': {
                    bg: 'bg.hover',
                  },
                }
              : {},
        };
      }}
    />
  );
};
