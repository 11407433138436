import { Dispatch, SetStateAction } from 'react';
import { MetricsTableData, areArraysOfObjectsEqual } from '../../../MetricAnswers.hooks';
import { Box, Checkbox } from '@chakra-ui/react';
import {
  recursivelyCheckSubRows,
  recursivelyGetIndeterminateState,
  updateSelectedRows,
} from './CheckboxSelector.hooks';

export const MetricCheckboxSelector = ({
  row,
  selectedRows,
  setSelectedRows,
  isDisabled,
}: {
  row: MetricsTableData;
  selectedRows: MetricsTableData[];
  setSelectedRows: Dispatch<SetStateAction<MetricsTableData[]>>;
  isDisabled: boolean;
}) => {
  const hasSubRows = !!row.subRows && row.subRows?.length > 0;
  const subRows = row.subRows ?? [];

  const isChecked = hasSubRows
    ? recursivelyCheckSubRows(subRows, selectedRows)
    : selectedRows.some(
        (r) =>
          r.metric.reference === row.metric.reference && areArraysOfObjectsEqual(r.tags, row.tags)
      );

  const isIndeterminate =
    hasSubRows && !isChecked && recursivelyGetIndeterminateState(subRows, selectedRows);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.currentTarget.checked;

    if (hasSubRows) {
      setSelectedRows?.((cur) => {
        const updatedRows = updateSelectedRows(subRows, checked, cur);
        return checked
          ? [...cur, ...updatedRows]
          : cur.filter(
              (item) =>
                !updatedRows.some(
                  (subRow) =>
                    subRow.metric.reference === item.metric.reference &&
                    areArraysOfObjectsEqual(subRow.tags, item.tags)
                )
            );
      });
    } else {
      if (checked) {
        setSelectedRows?.((cur) => [...cur, row]);
      } else {
        setSelectedRows?.((cur) =>
          cur.filter(
            (item) =>
              item.metric.reference !== row.metric.reference ||
              !areArraysOfObjectsEqual(item.tags, row.tags)
          )
        );
      }
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Checkbox
        isDisabled={isDisabled}
        isChecked={isChecked}
        onChange={handleCheckboxChange}
        isIndeterminate={isIndeterminate}
      />
    </Box>
  );
};
