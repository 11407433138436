import { Parser } from 'expr-eval';
import { isArray, isEqual, uniq } from 'lodash';
import { Answer, AnswerSet, ResolvedQuestion } from 'models';
import {
  isQuestionApplicable,
  isQuestionAligned,
  isQuestionSC,
  isSCQuestionAligned,
  isQuestionRequired,
} from 'utils/scores/questions';

// const parser = new Parser({ operators: { in: true } }); // TODO: Can it be done just like this? Is overwriting the operations necessary?

const parser = new Parser({
  operators: {
    logical: true,
    comparison: true,
    factorial: false,
    in: true,
  },
}) as Parser & { binaryOps: any };

parser.binaryOps['=='] = (a: any, b: any) =>
  (isArray(a) && !isArray(b) && a.includes(b)) ||
  (isArray(a) && isArray(b) && isEqual(a, b)) ||
  a === b;

// eslint-disable-next-line @typescript-eslint/dot-notation
parser.binaryOps['in'] = (a: any, b: any) =>
  (isArray(a) && isArray(b) && a.every((e) => b.includes(e))) || b.includes(a);

const KNOWN_VARIABLES = {
  this: 'this', //'answer to this question'
  YES: 'YES',
  NO: 'NO',
  NA: 'NA',
  YESNO: '[YES,NO]',
  YESNA: '[YES,NA]',
  ALL: '[YES,NO,NA]',
  TRUE: true,
  FALSE: false,
  DNSH: 'DO_NO_SIGNIFICANT_HARM',
  SC: 'SUBSTANTIAL_CONTRIBUTION',
  UNDEFINED: 'UNDEFINED',
  GREEN: 'GREEN',
  ENABLING: 'ENABLING',
  TRANSITIONAL: 'TRANSITIONAL',
};
export const NOT_EDITABLE = {} as Answer;

export const ALL_QUESTIONS = 'ALL_QUESTIONS';

const getCustomFunctions = (allQuestions: ResolvedQuestion[]) => {
  return {
    isAligned: (questionId: string) => {
      const question = allQuestions.find((q) => q.uniqueId === questionId);
      if (!question)
        throw Error(
          `Unknown question: ${questionId}. It might be further on the list, so it is not resolved yet`
        );
      return question.isAligned;
    },
    allQuestionSetsAligned: (questionsets: string[]) => {
      const applicable = allQuestions.filter(isQuestionRequired);
      const existingQuestionSets = uniq(
        allQuestions.flatMap((q) => q.questionSets).map((qs) => qs.questionSet.reference)
      );

      const apllicablePerQuestionSet = existingQuestionSets.reduce(
        (agg, cur) => ({
          ...agg,
          [cur]: applicable.filter((q) =>
            q.questionSets.some((qs) => qs.questionSet.reference === cur)
          ),
        }),
        {} as Record<string, ResolvedQuestion[]>
      );

      const result = questionsets.every((qs) => {
        if (!apllicablePerQuestionSet[qs]) throw Error(`Unknown questionset ${qs}!`);

        return apllicablePerQuestionSet[qs].every(isQuestionAligned);
      });
      return result;
    },
    allQuestionSetsSCAligned: (questionsets: string[]) => {
      const applicable = allQuestions.filter(isQuestionRequired).filter(isQuestionSC);
      const existingQuestionSets = uniq(
        allQuestions.flatMap((q) => q.questionSets).map((qs) => qs.questionSet.reference)
      );

      const apllicablePerQuestionSet = existingQuestionSets.reduce(
        (agg, cur) => ({
          ...agg,
          [cur]: applicable.filter((q) =>
            q.questionSets.some((qs) => qs.questionSet.reference === cur)
          ),
        }),
        {} as Record<string, ResolvedQuestion[]>
      );

      const result = questionsets.every((qs) => {
        if (!apllicablePerQuestionSet[qs]) throw Error(`Unknown questionset ${qs}!`);

        return (
          apllicablePerQuestionSet[qs].every(isQuestionAligned) &&
          apllicablePerQuestionSet[qs].filter(isQuestionSC).every(isSCQuestionAligned)
        );
      });
      return result;
    },
    listContains: (
      answers: string[],
      positiveOptions: string[],
      minOptions = 0,
      maxOptions = 0
    ) => {
      if (answers.filter((a) => !positiveOptions.includes(a)).length > 0) return false;
      return (
        answers.length >= minOptions && answers.length <= (maxOptions || positiveOptions.length)
      );
    },
  };
};

export const parseExpression = (condition: string) => {
  try {
    return parser.parse(condition);
  } catch (error) {
    // TODO: Set up error boundary around assessment to catch this and show appropriate error message
    throw new Error(`Cannot parse condition "${condition}": ${(error as Error).message}`);
  }
};

export const DEFAULT_EXPRESSION = 'TRUE';

export const resolveCondition = (
  condition: string | null | undefined,
  activityAnswers: AnswerSet = {},
  vars: { [key: string]: any; [ALL_QUESTIONS]: ResolvedQuestion[] } = { [ALL_QUESTIONS]: [] },
  debug?: boolean
) => {
  const expr = parseExpression(condition || DEFAULT_EXPRESSION);
  const all = vars[ALL_QUESTIONS] as ResolvedQuestion[];

  const values: any = { ...KNOWN_VARIABLES, ...vars, ...(all ? getCustomFunctions(all) : {}) };
  let result: undefined | false | null;
  let hasDependencies = false;
  let allDependenciesAreNotEditable = true;
  const comparesWithUndefined = expr.variables().includes(KNOWN_VARIABLES.UNDEFINED);
  if (debug) {
    console.log('Condition: ', condition, ' exp', expr, expr.variables());
  }

  expr.variables().forEach((name) => {
    if (debug) {
      console.log('Variable name: ', name);
    }
    if (values[name] === undefined) {
      if (debug) {
        console.log('values[name] === undefined: ', true);
      }

      // Has dependecy on other questions (Not one of the known variables to check ie not straight forward check)
      hasDependencies = true;
      const answer = activityAnswers[name];
      if (answer === NOT_EDITABLE) {
        if (debug) {
          console.log('Answer is NOT editable ');
        }

        // Undefined variables leads to exception when evaluating the expression
        // Replacing them with the magic keyword 'UNDEFINED' makes it possible to still
        // evaluate the expression even if some dependent questions have not been answered yet
        // Use case: When using OR in the expression, it is necessary to still evaluate even
        // if not all dependencies are ansawered i.e. the expression "if Q1 = NO or Q2 = NO"
        // then this question should be editable when Q1 is NO, even if Q2 is not editable (for some reason)
        // PROBLEM: If using negation, we may make the question editable too early. "if Q1 != NO & Q2 !== NO"
        // this would make the current question editable too early, if Q1 is answered, but Q2 is not editable
        // this question would be editable because the answer is not NO, but UNDEFINED, while the intention
        // This is decent trade-off at the moment, since we generally don't use negation.
        values[name] = KNOWN_VARIABLES.UNDEFINED;
        return;
      } else {
        if (debug) {
          console.log('Answer IS editable: ');
        }
        values[name] = answer?.data ?? KNOWN_VARIABLES.UNDEFINED;
      }
      allDependenciesAreNotEditable = false;
    }
  });
  // If question has dependencies and none of them are editable
  // this question should also not be editable UNLESS the expression
  // is comparing one of the dependencies to UNDEFINED. In that case,
  // we should evaluate it like normal, since we expect some dependency to not be there
  if (hasDependencies && allDependenciesAreNotEditable && !comparesWithUndefined) {
    return false;
  }
  if (result !== undefined) {
    return result;
  }
  const evaluated = expr.evaluate(values);

  if (debug) {
    console.log('evaluated ', evaluated, values);
  }

  return evaluated;
};
