import { Skeleton, HStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { AttachIcon } from 'Tokens/Icons/Function';
import { AttachmentBox, useGetMetricAnswerDocumentationByIdQuery } from 'models';
import { useMemo } from 'react';
import { MetricsTableData } from '..';
import { useGetDatapointValues } from '../MetricsTable/MetricsTableComponents/InputFields/QuantitativeInputs/QuantitativeInputs.hooks';

export const MetricsAttachmentsLabel = ({
  row,
  companyReportingUnitId,
}: {
  row: MetricsTableData;
  companyReportingUnitId?: string;
}) => {
  const { answer } = useGetDatapointValues(row, companyReportingUnitId);
  const answerId = useMemo(() => answer?.id, [answer]);
  const { data: documentationData, loading: documentationLoading } =
    useGetMetricAnswerDocumentationByIdQuery({
      variables: {
        metricAnswerId: answerId,
      },
      skip: !answerId,
    });

  const attachmentBox: AttachmentBox | undefined = useMemo(
    () => documentationData?.esrs_Answer_by_pk?.attachmentBox ?? undefined,
    [documentationData]
  );

  const attachmentCount = useMemo(() => {
    return (attachmentBox?.attachments ?? []).length;
  }, [attachmentBox]);

  return (
    <Skeleton isLoaded={!documentationLoading}>
      {attachmentCount > 0 ? (
        <HStack>
          <AttachIcon />
          <Typography variant="body">{attachmentCount}</Typography>
        </HStack>
      ) : (
        <Typography variant="body">—</Typography>
      )}
    </Skeleton>
  );
};
