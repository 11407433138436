import {
  useGetActivityObjectivesStatusQuery,
  GetActivityObjectivesStatusQuery_,
  Activity,
} from 'models';
import { STATUS_VARIANTS, TAG_STATUS, VariantType } from 'Molecules/ObjectivesTiles';
import { useMemo } from 'react';

export const useActivityObjectivesStatus = (activityRef: string, versionNumber: number) => {
  const objectiveStatus: { [key: string]: number | undefined } = {};

  const { data, loading } = useGetActivityObjectivesStatusQuery({
    variables: {
      activityRef,
      versionNumber,
    },
    skip: !activityRef || !versionNumber,
  });
  const activityVersionData = data?.ActivityVersion[0];

  const activityVersionKeys = Object.keys(activityVersionData ?? {}) as unknown as [
    keyof GetActivityObjectivesStatusQuery_['ActivityVersion'][number],
  ];

  activityVersionKeys.forEach((activityVersionKey) => {
    const key = activityVersionKey;
    if (key !== 'activity' && key !== 'tagObjective' && key !== 'tagExpression') {
      objectiveStatus[key] = activityVersionData?.[key]?.aggregate?.count;
    }
  });

  return { data: objectiveStatus, loading };
};

export const useActivityTagObjectives = (activityRef: string, versionNumber: number) => {
  const { data, loading } = useGetActivityObjectivesStatusQuery({
    variables: {
      activityRef,
      versionNumber,
    },
    skip: !activityRef || !versionNumber,
  });
  const activityVersion = data?.ActivityVersion[0];

  return {
    data: {
      tagObjective: activityVersion?.tagObjective,
      tagExpression: activityVersion?.tagExpression,
    },
    loading,
  };
};

export const useObjectiveStatusHelpers = (activity: Activity) => {
  const { data: objectiveStatusData, loading: objectiveStatusLoading } =
    useActivityObjectivesStatus(
      activity?.reference ?? '',
      activity?.currentQuestionSetVersion ?? 1
    );

  const objectiveStatus = useMemo(() => {
    if (!objectiveStatusLoading) return objectiveStatusData;
    return {};
  }, [objectiveStatusLoading]);

  const getObjectiveStatusStyles = (objective: keyof VariantType) => {
    if (!!objectiveStatus[`${objective}SC`]) {
      return STATUS_VARIANTS.SC;
    } else if (!!objectiveStatus[`${objective}DNSH`]) {
      return STATUS_VARIANTS.DNSH;
    } else {
      return STATUS_VARIANTS.NotRelevant;
    }
  };

  const getObjectiveStatusKey = (objective: keyof VariantType) => {
    if (!!objectiveStatus[`${objective}SC`]) {
      return 'SC';
    } else if (!!objectiveStatus[`${objective}DNSH`]) {
      return 'DNSH';
    } else {
      return 'NotRelevant';
    }
  };

  return { getObjectiveStatusKey, getObjectiveStatusStyles };
};

export const objectiveExpressionTag = (tagExpression?: string) => {
  if (tagExpression === 'ENABLING') {
    return TAG_STATUS.Enabling.status;
  } else if (tagExpression === 'TRANSITIONAL') {
    return TAG_STATUS.Transitional.status;
  } else if (tagExpression?.includes('ENABLING')) {
    return TAG_STATUS.CanBeEnabling.status;
  } else if (tagExpression?.includes('TRANSITIONAL')) {
    return TAG_STATUS.CanBeTransitional.status;
  } else return TAG_STATUS.Default.status;
};
