import { useDisclosure } from '@chakra-ui/react';
import { Modal } from 'Molecules';
import { Menu } from 'Molecules/Menu';
import { Typography } from 'Tokens';
import { AddIcon, RemoveIcon } from 'Tokens/Icons/Function';
import { useGetDatapointValues } from '../InputFields/QuantitativeInputs/QuantitativeInputs.hooks';
import { MetricsTableData } from '../../..';
import { useMemo, useState } from 'react';
import { TimePeriodsEnums } from 'containers/Esrs/pieces/DisclosureRequirements/Requirement';
import { Button } from 'Atoms';
import { AdditionalInfoModal } from '../../../InputTable/AdditionalInformation/AdditionalInfoModal';

export const AdditionalInfoButton = ({
  metric,
  companyReportingUnit,
}: {
  metric: MetricsTableData['metric'];
  companyReportingUnit: string;
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedMetric, setSelectedMetric] = useState<MetricsTableData['metric']>();

  return (
    <>
      <Button
        variant="ghost"
        leftIcon={<AddIcon />}
        onClick={() => {
          setSelectedMetric(metric);
          onOpen();
        }}
      >
        Additional information
      </Button>
      <AdditionalInfoModal
        onClose={onClose}
        isOpen={isOpen}
        metric={
          (selectedMetric as MetricsTableData['metric']) ?? ({} as MetricsTableData['metric'])
        }
        companyReportingUnit={companyReportingUnit}
      />
    </>
  );
};
export const RemoveAdditionalInfo = ({
  metric,
  companyReportingUnit,
  placement,
}: {
  metric: MetricsTableData['metric'];
  companyReportingUnit: string | undefined;
  placement: string;
}) => {
  const {
    isOpen: isRemoveInfoModalOpen,
    onClose: onRemoveInfoModalClose,
    onOpen: onRemoveInfoModalOpen,
  } = useDisclosure();
  const { onDatapointChange, answer, dataPointPerYear } = useGetDatapointValues(
    {
      metric,
    },
    companyReportingUnit ?? ''
  );

  const data = useMemo(
    () =>
      placement === 'before'
        ? dataPointPerYear?.metadata?.before
        : dataPointPerYear?.metadata?.after,
    [placement, dataPointPerYear]
  );

  const onConfirm = () => {
    const metadata = {
      ...dataPointPerYear?.metadata,
      [placement]: {
        ...(data ?? {}),
        isVisible: false,
      },
    };
    onDatapointChange({
      value: dataPointPerYear?.value ?? '',
      factValue: dataPointPerYear?.factValue,
      hasOptedOut: answer?.hasOptedOut ?? false,
      optOutReason: answer?.optOutReason ?? '',
      dp: dataPointPerYear ?? { timeframe: TimePeriodsEnums.year },
      metadata: metadata,
    }).then(() => onRemoveInfoModalClose());
  };

  return (
    <>
      <Menu
        sections={[
          {
            actions: [
              {
                id: 'remove',
                title: 'Remove',
                leftElement: <RemoveIcon />,
                onClick: () => onRemoveInfoModalOpen(),
              },
            ],
          },
        ]}
      />
      <Modal
        isOpen={isRemoveInfoModalOpen}
        onClose={onRemoveInfoModalClose}
        size="xs"
        hasHeader={false}
        confirmText="Remove"
        confirmVariant="destructive"
        onConfirm={onConfirm}
      >
        <Typography variant="bodyStrong">
          Are you sure you want to remove the “Additional information” field?
        </Typography>
      </Modal>
    </>
  );
};
